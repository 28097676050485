import axios from 'axios'

export default class NotificationService {
  static list = (parameters) =>
    axios.get('/notifications', { params: parameters, excludeLoader: true })
  static read = (id) =>
    axios.post(`/notifications/${id}/read`, null, { excludeLoader: true })
  static unread = (id) =>
    axios.post(`/notifications/${id}/unread`, null, { excludeLoader: true })
  static unreadCount = () =>
    axios.get(`/notifications/unreadCount`, {
      excludeLoader: true,
    })
  static latest = () =>
    axios.get(`/notifications/latest`, { excludeLoader: true })
}
